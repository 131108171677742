import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const smsPackageList = async () => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'packagesms/list',
      body: {},
    })
    .then(res => (res.data ? res.data : []))
    .catch(err => {
      console.log('get sms package list err : ', err)

      return []
    })

  return response
}

const smsPackageGet = async id => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'packagesms',
      param: id,
    })
    .then(res => (res.data ? res.data : {}))
    .catch(err => {
      console.log('get sms package id err : ', err)

      return {}
    })

  return response
}

const buyPackageSms = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'packagesms',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('Buy package sms : ', err)

      return {}
    })

  return response
}
export default { smsPackageList, smsPackageGet, buyPackageSms }
