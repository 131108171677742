import refreshToken from '@/plugins/refresh-token'
import { api } from './main'

const bankList = async () => {
  //await refreshToken()
  const response = await api
    .get({
      path: 'ref/bank',
    })
    .then(res => (res.data ? res.data : []))
    .catch(err => {
      console.log('get bank list err : ', err)

      return []
    })

  return response
}
export default { bankList }
