import { ref } from '@vue/composition-api'
import smsPackage from '@/api/systemSetting/smsPackage'
import { i18n } from '@/plugins/i18n'

export default function useSmsPackage() {
  const dataTableList = ref([])
  const options = ref({})
  const loading = ref(true)
  const tableColumns = [
    { text: i18n.t('package'), value: 'package_name' },
    { text: i18n.t('quantity'), value: 'package_cost', align: 'end' },
    { text: i18n.t('price'), value: 'package_amount', align: 'end' },
    { text: i18n.t('option'), value: 'package_id', align: 'center' },
  ]
  const { smsPackageList } = smsPackage

  smsPackageList().then(res => {
    dataTableList.value = res
    options.value.page = -1
    loading.value = false
  })

  return {
    dataTableList,
    options,
    loading,
    tableColumns,
  }
}
