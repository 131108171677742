<template>
  <div>
    <v-dialog
      v-model="isBuyPackageSms"
      persistent
      max-width="500"
    >
      <v-card :loading="loading">
        <v-card-title>
          {{ $t("order_sms_package") }}
        </v-card-title>
        <v-form
          ref="formBuySms"
          @submit.prevent="sendBuysms"
        >
          <v-card-text>
            <v-radio-group v-model="package_detail.bank_id">
              <v-radio
                v-for="(n, index) in dataBankList"
                :key="index"
                :value="n.bank_id"
              >
                <template
                  v-slot:label
                >
                  {{ n.bank_name }} {{ n.bank_branch }} เลขที่บัญชี
                  {{ n.bank_account_number }} ชื่อบัญชี {{ n.bank_account_name }}
                </template>
              </v-radio>
            </v-radio-group>
            <v-text-field
              v-model="package_detail.package_name"
              :label="$t('choose_service_fee') + ' '+ 'SMS'"
              disabled
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="package_detail.shop"
              :label="$t('shop')"
              disabled
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="package_detail.user"
              :label="$t('transfer_by_name_phone')"
              outlined
              :rules="[required]"
              dense
            ></v-text-field>

            <v-text-field
              v-model="package_detail.package_cost"
              :label="$t('amount_baht')"
              outlined
              :rules="[required]"
              dense
            ></v-text-field>

            <v-dialog
              ref="menu"
              v-model.trim="menu1"
              :return-value.sync="package_detail.payment_date"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="package_detail.payment_date"
                  outlined
                  dense
                  :rules="[required]"
                  :label="$t('transfer_date')"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model.trim="package_detail.payment_date"
                :locale="$i18n.locale"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(package_detail.payment_date)"
                >
                  {{ $t("confirm") }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="menu1 = false"
                >
                  {{ $t("cancel") }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-date-picker>
            </v-dialog>

            <v-dialog
              ref="menux"
              v-model.trim="menu2"
              :return-value.sync="package_detail.payment_time"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="package_detail.payment_time"
                  dense
                  outlined
                  :rules="[required]"
                  :label="$t('transfer_time')"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model.trim="package_detail.payment_time"
                scrollable
                format="24hr"
              >
                <v-spacer></v-spacer>

                <v-btn
                  text
                  color="primary"
                  @click="$refs.menux.save(package_detail.payment_time)"
                >
                  {{ $t("confirm") }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="menu2 = false"
                >
                  {{ $t("cancel") }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-time-picker>
            </v-dialog>

            <input
              ref="selectImage"
              type="file"
              style="display: none"
              @change="previewFiles"
            />
          </v-card-text>
          <v-card-text class="d-flex">
            <v-avatar
              rounded
              size="120"
              class="v-avatar-light-bg primary--text me-6"
            >
              <v-img
                contain
                :src="blob_image"
              ></v-img>
            </v-avatar>
            <!-- upload photo -->
            <div>
              <v-btn
                color="primary"

                @click="$refs.refInputEl.click()"
              >
                <v-icon class="d-sm-none">
                  {{ icons.mdiCloudUploadOutline }}
                </v-icon>
                <span class="d-none d-sm-block">{{ $t('upload_pictures') }}</span>
              </v-btn>

              <input
                ref="refInputEl"
                type="file"
                accept=".jpeg,.png,.jpg,GIF"
                :hidden="true"
                @change="previewFiles"
              />

              <p class="text-sm mt-5">
                {{ $t('function_features_5') }}
              </p>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading || !package_detail.payment_evidence"
            >
              {{
                $t("save")
              }}
            </v-btn>
            <v-btn
              color="secondarts"
              outlined
              @click="$emit('update:is-buy-package-sms',false)"
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { mdiCloudUploadOutline } from '@mdi/js'
import bank from '@/api/bank'
import smsPackage from '@/api/systemSetting/smsPackage'
import store from '@/store'
import { required } from '@/@core/utils/validation'

export default {
  model: {
    prop: 'isBuyPackageSms',
    event: 'update:is-buy-package-sms',
  },
  props: {
    isBuyPackageSms: {
      type: Boolean,
      default: false,
    },
    dataBuy: {
      type: Object,
      default() {},
    },
  },
  setup(props, { emit }) {
    const menu1 = ref(false)
    const menu2 = ref(false)
    const package_detail = ref({})
    const dataBankList = ref([])
    const blob_image = ref(null)
    const loading = ref(false)
    const formBuySms = ref(null)

    const { bankList } = bank
    const { smsPackageGet, buyPackageSms } = smsPackage
    bankList().then(res => {
      dataBankList.value = res
    })

    const previewFiles = event => {
      package_detail.value.payment_evidence = event.target.files[event.target.files.length - 1]
      blob_image.value = URL.createObjectURL(event.target.files[event.target.files.length - 1])
    }

    const sendBuysms = () => {
      const isFormValid = formBuySms.value.validate()
      if (!isFormValid) return
      loading.value = true
      const formData = new FormData()
      formData.append('package_id', package_detail.value.package_id)
      formData.append('bank_id', package_detail.value.bank_id)
      formData.append('payment_by', package_detail.value.user)
      formData.append('payment_cost', package_detail.value.package_cost)
      formData.append('payment_date', package_detail.value.payment_date)
      formData.append('payment_time', package_detail.value.payment_time)
      formData.append('payment_evidence', package_detail.value.payment_evidence)

      buyPackageSms(formData).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        blob_image.value = null
        emit('update:is-buy-package-sms', false)
      })
    }
    watch(() => props.isBuyPackageSms, val => {
      loading.value = true
      if (val) {
        smsPackageGet(props.dataBuy.package_id).then(res => {
          package_detail.value = res
          package_detail.value.bank_id = '2'
          loading.value = false
          blob_image.value = null
        })
      }
    })

    return {
      menu1,
      menu2,
      dataBankList,
      package_detail,
      loading,
      blob_image,
      formBuySms,
      required,
      previewFiles,
      sendBuysms,
      icons: {
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>
