<template>
  <div>
    <BuyPackageSms
      v-model="isBuyPackageSms"
      :data-buy="dataBuy"
    />
    <v-card>
      <v-card-title>
        {{ $t('sms_package') }}
      </v-card-title>
      <v-data-table
        :headers="tableColumns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        hide-default-footer
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.package_cost`]="{item}">
          {{ item.package_cost | formatPrice }}
        </template>
        <template v-slot:[`item.package_amount`]="{ item}">
          {{ item.package_amount | formatPrice }}
        </template>
        <template v-slot:[`item.package_id`]="{item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="dataBuy =item, isBuyPackageSms = true"
              >
                <v-icon>{{ icons.mdiCart }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("order_buy") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { mdiCart } from '@mdi/js'
import { ref } from '@vue/composition-api'
import useSmsPackage from './useSmsPackage'
import { formatPrice } from '@/plugins/filters'
import BuyPackageSms from './BuyPackageSms.vue'

export default {
  filters: {
    formatPrice,
  },
  components: {
    BuyPackageSms,
  },
  setup() {
    const isBuyPackageSms = ref(false)
    const dataBuy = ref({})
    const {
      dataTableList,
      options,
      loading,
      tableColumns,
    } = useSmsPackage()

    return {
      dataBuy,
      isBuyPackageSms,
      dataTableList,
      options,
      loading,
      tableColumns,
      icons: {
        mdiCart,
      },
    }
  },

}
</script>
